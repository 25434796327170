@import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200');

.m-icon {
  font-family: 'Material Symbols Outlined';
  font-variation-settings:
    'FILL' 0,
    'wght' 400,
    'GRAD' 0,
    'opsz' 24;
  font-size: medium;
}

.button {
  background: none;
  border: none;
  width: 30px;
  height: 30px;
  border-radius: 16px;
  text-align: center;
  padding: 0;
  cursor: pointer;
  color: inherit;
  > .undo,
  .redo {
    font-size: 1rem;
  }
  > :hover {
    background: #eeeeee;
  }
}

.create {
  font-size: 3rem;
  border-radius: 8px;
  aspect-ratio: 1;
  padding: 12px;
  color: inherit;
  cursor: pointer;
  > :hover {
    animation: shaking 0.25s infinite;
    background: #ff5f00;
    color: white;
  }
}
