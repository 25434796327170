.frac {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.frac .frac-line {
  width: 120%;
  border-top: 1px solid black;
}
