.axis {
  width: 100%;
  position: relative;
  height: 10px;
}

.axis .tick-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
}

.axis .tick {
  height: 100%;
  border-right: 1px solid black;
}

.axis .line {
  position: absolute;
  top: 50%;
  width: 100%;
  border-top: 1px solid black;
  transform: translateY(-50%);
}

.axis .label-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.axis .label {
  position: absolute;
  transform: translateX(-50%);
}

.axis .unit {
  position: absolute;
  right: 0%;
  transform: translateX(100%);
  padding-left: 8px;
}
