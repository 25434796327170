@import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200');

.m-icon {
  font-family: 'Material Symbols Outlined';
  font-variation-settings:
    'FILL' 0,
    'wght' 400,
    'GRAD' 0,
    'opsz' 24;
  font-size: medium;
}

.cross-math.content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 60px;
  padding: 60px;
  box-sizing: border-box;
}

.container {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 1040px;
  justify-content: space-around;
  gap: 40px;
  align-items: center;
}

.dashboard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 400px;
  gap: 20px;
}

.options {
  display: flex;
  flex-direction: column;
  place-items: center;
  gap: 4px;
  font-weight: 500;
}

.form {
  display: flex;
  gap: 12px;
  align-items: center;
}

.option-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-around;
}

.puzzle-for-print {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  & .puzzle-container {
    box-shadow: none;
  }
}

.puzzle-for-print {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  & .puzzle-container {
    box-shadow: none;
  }
}

.controller {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
}

@keyframes shaking {
  0% {
    transform: translate(0, 0) rotate(0deg);
  }
  25% {
    transform: translate(5px, 5px) rotate(5deg);
  }
  50% {
    transform: translate(0, 0) rotate(0eg);
  }
  75% {
    transform: translate(-5px, 5px) rotate(-5deg);
  }
  100% {
    transform: translate(0, 0) rotate(0deg);
  }
}

.puzzle {
  display: grid;
  max-width: 100%;
  max-height: 100%;
}

.tile {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 800;
  font-size: 2rem;
  aspect-ratio: 1;
  height: 100%;
}

.puzzle > .tile.span-col {
  grid-column: span 2;
}
.puzzle > .tile.span-row {
  grid-row: span 2;
}

.tile.operand {
  border: 1px solid black;
  border-radius: 100px;
}

.tile.operand,
.tile.operator {
  cursor: pointer;
}

.tile.operand:hover,
.tile.operator:hover {
  color: #eeeeee;
  border-color: #eeeeee;
}

.tile:has(.fixed-flag) {
  color: #ff5f00;
  border-color: #ff5f00;
}

.fixed-flag {
  position: absolute;
  right: 0;
  top: 0;
  font-variation-settings:
    'FILL' 1,
    'wght' 400,
    'GRAD' 0,
    'opsz' 24;
  font-size: 1.2rem;
  rotate: 36deg;
}

@media (max-width: 1200px) {
  .content {
    padding: 40px;
  }
  .container {
    flex-direction: column;
  }
  .puzzle-container {
    padding: 30px 20px 20px 30px;
  }
  .tile {
    font-size: 1.5rem;
  }
  .domain-list {
    gap: 2px;
  }
}
