.puzzle-container {
  width: 100%;
  height: 100%;
  max-width: 500px;
  max-height: 500px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 60px 40px 40px 60px;
  border-radius: 40px;
  box-shadow: 0px 0 10px 6px #eeeeee;
  justify-content: center;
  box-sizing: border-box;
}

.puzzle {
  display: grid;
  max-width: 100%;
  max-height: 100%;
}

.tile {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 800;
  font-size: 2rem;
  aspect-ratio: 1;
  height: 100%;

  > .span-col {
    grid-column: span 2;
  }
  > .span-row {
    grid-row: span 2;
  }

  &.operand,
  &.operator {
    cursor: pointer;
    &:hover {
      color: #eeeeee;
      border-color: #eeeeee;
    }
  }

  &:has(.fixed-flag) {
    color: #ff5f00;
    border-color: #ff5f00;
  }
  &.operand {
    border: 1px solid black;
    border-radius: 100px;
  }
}

.fixed-flag {
  position: absolute;
  right: 0;
  top: 0;
  font-variation-settings:
    'FILL' 1,
    'wght' 400,
    'GRAD' 0,
    'opsz' 24;
  font-size: 1.2rem;
  rotate: 36deg;
}
