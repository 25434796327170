.chunk {
  position: relative;
  display: flex;
  width: 100%;
}

.chunk .block.filled,
.chunk .block.shaded {
  border-top: 1px solid olive;
  border-bottom: 1px solid olive;
}

.chunk .label {
  z-index: 1;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  top: 50%;
  transform: translate(-50%, -50%);
}

.chunk .label .number {
  z-index: 2;
}

.chunk .block {
  flex-grow: 1;
  height: 80px;
}

.chunk .block.filled {
  background-color: wheat;
}

.chunk .divider-container {
  position: absolute;
  height: 100%;
  display: flex;
  justify-content: space-between;
}

.chunk .divider {
  height: 100%;
  border-right: 1px dotted olive;
}

.chunk .divider.solid {
  border-right-style: solid;
}
