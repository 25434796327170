@import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200');

.m-icon {
  font-family: 'Material Symbols Outlined';
  font-variation-settings:
    'FILL' 0,
    'wght' 400,
    'GRAD' 0,
    'opsz' 24;
  font-size: medium;
}

.division-diagram.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  padding-bottom: 200px;
  padding-top: 100px;
}

.division-diagram h1 {
  width: 80%;
  text-align: left;
}

.togglable {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 20px;
  min-width: 20px;
  padding: 8px;
  border: 1px solid black;
  border-radius: 8px;
  background-color: white;
}

.togglable:hover {
  cursor: pointer;
}

button:hover {
  cursor: pointer;
}
